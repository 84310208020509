// source: public/yaticker.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.yaticker', null, global);
goog.exportSymbol('proto.yaticker.MarketHoursType', null, global);
goog.exportSymbol('proto.yaticker.OptionType', null, global);
goog.exportSymbol('proto.yaticker.QuoteType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yaticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yaticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yaticker.displayName = 'proto.yaticker';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yaticker.prototype.toObject = function(opt_includeInstance) {
  return proto.yaticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yaticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yaticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    time: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exchange: jspb.Message.getFieldWithDefault(msg, 5, ""),
    quotetype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    markethours: jspb.Message.getFieldWithDefault(msg, 7, 0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    dayvolume: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dayhigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    daylow: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    change: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    shortname: jspb.Message.getFieldWithDefault(msg, 13, ""),
    expiredate: jspb.Message.getFieldWithDefault(msg, 14, 0),
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    previousclose: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    strikeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    underlyingsymbol: jspb.Message.getFieldWithDefault(msg, 18, ""),
    openinterest: jspb.Message.getFieldWithDefault(msg, 19, 0),
    optionstype: jspb.Message.getFieldWithDefault(msg, 20, 0),
    minioption: jspb.Message.getFieldWithDefault(msg, 21, 0),
    lastsize: jspb.Message.getFieldWithDefault(msg, 22, 0),
    bid: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    bidsize: jspb.Message.getFieldWithDefault(msg, 24, 0),
    ask: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    asksize: jspb.Message.getFieldWithDefault(msg, 26, 0),
    pricehint: jspb.Message.getFieldWithDefault(msg, 27, 0),
    vol24hr: jspb.Message.getFieldWithDefault(msg, 28, 0),
    volallcurrencies: jspb.Message.getFieldWithDefault(msg, 29, 0),
    fromcurrency: jspb.Message.getFieldWithDefault(msg, 30, ""),
    lastmarket: jspb.Message.getFieldWithDefault(msg, 31, ""),
    circulatingsupply: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    marketcap: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yaticker}
 */
proto.yaticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yaticker;
  return proto.yaticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yaticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yaticker}
 */
proto.yaticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchange(value);
      break;
    case 6:
      var value = /** @type {!proto.yaticker.QuoteType} */ (reader.readEnum());
      msg.setQuotetype(value);
      break;
    case 7:
      var value = /** @type {!proto.yaticker.MarketHoursType} */ (reader.readEnum());
      msg.setMarkethours(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChangepercent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setDayvolume(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDayhigh(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDaylow(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChange(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setExpiredate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOpenprice(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPreviousclose(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStrikeprice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingsymbol(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setOpeninterest(value);
      break;
    case 20:
      var value = /** @type {!proto.yaticker.OptionType} */ (reader.readEnum());
      msg.setOptionstype(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setMinioption(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setLastsize(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBid(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setBidsize(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAsk(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAsksize(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setPricehint(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setVol24hr(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setVolallcurrencies(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromcurrency(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastmarket(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCirculatingsupply(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yaticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yaticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yaticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yaticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeSint64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExchange();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQuotetype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMarkethours();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getDayvolume();
  if (f !== 0) {
    writer.writeSint64(
      9,
      f
    );
  }
  f = message.getDayhigh();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getDaylow();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getChange();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExpiredate();
  if (f !== 0) {
    writer.writeSint64(
      14,
      f
    );
  }
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getPreviousclose();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getStrikeprice();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getUnderlyingsymbol();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOpeninterest();
  if (f !== 0) {
    writer.writeSint64(
      19,
      f
    );
  }
  f = message.getOptionstype();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getMinioption();
  if (f !== 0) {
    writer.writeSint64(
      21,
      f
    );
  }
  f = message.getLastsize();
  if (f !== 0) {
    writer.writeSint64(
      22,
      f
    );
  }
  f = message.getBid();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getBidsize();
  if (f !== 0) {
    writer.writeSint64(
      24,
      f
    );
  }
  f = message.getAsk();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
  f = message.getAsksize();
  if (f !== 0) {
    writer.writeSint64(
      26,
      f
    );
  }
  f = message.getPricehint();
  if (f !== 0) {
    writer.writeSint64(
      27,
      f
    );
  }
  f = message.getVol24hr();
  if (f !== 0) {
    writer.writeSint64(
      28,
      f
    );
  }
  f = message.getVolallcurrencies();
  if (f !== 0) {
    writer.writeSint64(
      29,
      f
    );
  }
  f = message.getFromcurrency();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getLastmarket();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getCirculatingsupply();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getMarketcap();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.yaticker.QuoteType = {
  NONE: 0,
  ALTSYMBOL: 5,
  HEARTBEAT: 7,
  EQUITY: 8,
  INDEX: 9,
  MUTUALFUND: 11,
  MONEYMARKET: 12,
  OPTION: 13,
  CURRENCY: 14,
  WARRANT: 15,
  BOND: 17,
  FUTURE: 18,
  ETF: 20,
  COMMODITY: 23,
  ECNQUOTE: 28,
  CRYPTOCURRENCY: 41,
  INDICATOR: 42,
  INDUSTRY: 1000
};

/**
 * @enum {number}
 */
proto.yaticker.OptionType = {
  CALL: 0,
  PUT: 1
};

/**
 * @enum {number}
 */
proto.yaticker.MarketHoursType = {
  PRE_MARKET: 0,
  REGULAR_MARKET: 1,
  POST_MARKET: 2,
  EXTENDED_HOURS_MARKET: 3
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.yaticker.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float price = 2;
 * @return {number}
 */
proto.yaticker.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional sint64 time = 3;
 * @return {number}
 */
proto.yaticker.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.yaticker.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exchange = 5;
 * @return {string}
 */
proto.yaticker.prototype.getExchange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setExchange = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional QuoteType quoteType = 6;
 * @return {!proto.yaticker.QuoteType}
 */
proto.yaticker.prototype.getQuotetype = function() {
  return /** @type {!proto.yaticker.QuoteType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.yaticker.QuoteType} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setQuotetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional MarketHoursType marketHours = 7;
 * @return {!proto.yaticker.MarketHoursType}
 */
proto.yaticker.prototype.getMarkethours = function() {
  return /** @type {!proto.yaticker.MarketHoursType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.yaticker.MarketHoursType} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setMarkethours = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional float changePercent = 8;
 * @return {number}
 */
proto.yaticker.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional sint64 dayVolume = 9;
 * @return {number}
 */
proto.yaticker.prototype.getDayvolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setDayvolume = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float dayHigh = 10;
 * @return {number}
 */
proto.yaticker.prototype.getDayhigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setDayhigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float dayLow = 11;
 * @return {number}
 */
proto.yaticker.prototype.getDaylow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setDaylow = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float change = 12;
 * @return {number}
 */
proto.yaticker.prototype.getChange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setChange = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string shortName = 13;
 * @return {string}
 */
proto.yaticker.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional sint64 expireDate = 14;
 * @return {number}
 */
proto.yaticker.prototype.getExpiredate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setExpiredate = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional float openPrice = 15;
 * @return {number}
 */
proto.yaticker.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float previousClose = 16;
 * @return {number}
 */
proto.yaticker.prototype.getPreviousclose = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setPreviousclose = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float strikePrice = 17;
 * @return {number}
 */
proto.yaticker.prototype.getStrikeprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setStrikeprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string underlyingSymbol = 18;
 * @return {string}
 */
proto.yaticker.prototype.getUnderlyingsymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setUnderlyingsymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional sint64 openInterest = 19;
 * @return {number}
 */
proto.yaticker.prototype.getOpeninterest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setOpeninterest = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional OptionType optionsType = 20;
 * @return {!proto.yaticker.OptionType}
 */
proto.yaticker.prototype.getOptionstype = function() {
  return /** @type {!proto.yaticker.OptionType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.yaticker.OptionType} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setOptionstype = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional sint64 miniOption = 21;
 * @return {number}
 */
proto.yaticker.prototype.getMinioption = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setMinioption = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional sint64 lastSize = 22;
 * @return {number}
 */
proto.yaticker.prototype.getLastsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setLastsize = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional float bid = 23;
 * @return {number}
 */
proto.yaticker.prototype.getBid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setBid = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional sint64 bidSize = 24;
 * @return {number}
 */
proto.yaticker.prototype.getBidsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setBidsize = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional float ask = 25;
 * @return {number}
 */
proto.yaticker.prototype.getAsk = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setAsk = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional sint64 askSize = 26;
 * @return {number}
 */
proto.yaticker.prototype.getAsksize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setAsksize = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional sint64 priceHint = 27;
 * @return {number}
 */
proto.yaticker.prototype.getPricehint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setPricehint = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional sint64 vol_24hr = 28;
 * @return {number}
 */
proto.yaticker.prototype.getVol24hr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setVol24hr = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional sint64 volAllCurrencies = 29;
 * @return {number}
 */
proto.yaticker.prototype.getVolallcurrencies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setVolallcurrencies = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional string fromcurrency = 30;
 * @return {string}
 */
proto.yaticker.prototype.getFromcurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setFromcurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string lastMarket = 31;
 * @return {string}
 */
proto.yaticker.prototype.getLastmarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setLastmarket = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional double circulatingSupply = 32;
 * @return {number}
 */
proto.yaticker.prototype.getCirculatingsupply = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setCirculatingsupply = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional double marketcap = 33;
 * @return {number}
 */
proto.yaticker.prototype.getMarketcap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.yaticker} returns this
 */
proto.yaticker.prototype.setMarketcap = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


goog.object.extend(exports, proto);
